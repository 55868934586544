import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import ContactHero from "../sections/contact-hero"

const Contact = () => (
  <Layout>
    <Seo
      title="Contact Us"
      description="We are passionate about building energetic spaces with a creative edge. Our approach always stays sensitive to context and purposeful design is at the heart of everything we create."
    />
    <ContactHero />
  </Layout>
)

export default Contact
