import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Container, Col } from "react-bootstrap"

import YAMLdata from "./contact-hero.yaml"

const ContactHero = () => {
  return (
    <section className="contact_area pad_btm">
      <Container className="p0" fluid>
        <Row className="no-gutters">
          <Col
            xs={{ order: 2, span: 12 }}
            lg={{ order: 1, span: 5 }}
            xxl={{ order: 1, span: 4 }}
            className="contact_content"
          >
            <div className="contact_content_ph">
              {/* <h2>{YAMLdata.title}</h2> */}
              <address>
                <p className="black-dash">{YAMLdata.address}</p>
              </address>
              <p>
                <a
                  href={`tel: ${YAMLdata.primephone}`}
                  rel="noreferrer noopener"
                >
                  {YAMLdata.primephone}
                </a>
                <br />
                <a href={`tel: ${YAMLdata.secphone}`} rel="noreferrer noopener">
                  {YAMLdata.secphone}{" "}
                </a>
                <br />{" "}
                <a
                  href={`mailto: ${YAMLdata.primeemail}`}
                  rel="noreferrer noopener"
                >
                  {YAMLdata.primeemail}
                </a>
                <br /> <br />{" "}
                <a
                  href={YAMLdata.maplocation}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {YAMLdata.mapcopy}
                </a>
              </p>
            </div>
          </Col>
          <Col
            xs={{ order: 1, span: 12 }}
            lg={{ order: 2, span: 7 }}
            xxl={{ order: 2, span: 8 }}
            className="contact_image"
          >
            <StaticImage
              src="../images/contact/contact-hero.jpg"
              alt="Contact Us"
              layout="fullWidth"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default ContactHero
